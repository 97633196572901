<template>
  <div>
    <vx-card actionable class="cardx" title="Google Meet">
      <vs-row vs-w="12">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Focus
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="dropdown-login customDropDown" style="padding: 0px">
              <treeselect v-model="initSelected" :options="treeDatafocus" />
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <!-- <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Levels
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="dropdown-login customDropDown">
              <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-dropdown vs-custom-content vs-trigger-click style="margin-left: 3%">
            <a class="a-icon" href.prevent @click="showTreeBox">
              IIML Levels
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedLevelsIIML"
                :multiple="true"
                :options="treeDataLevelsIIML"
              />
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDE"
                :multiple="true"
                :options="treeDataBDE"
              />
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-dropdown vs-custom-content vs-trigger-click v-if="getrole('cm4')">
            <a class="a-icon" href.prevent @click="showTreeBox">
              SR
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDESR"
                :multiple="true"
                :options="treeDataBDESR"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              IIML SPOC
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedBDEIIML"
                :multiple="true"
                :options="treeDataBDEIIML"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-dropdown vs-custom-content vs-trigger-click>
            <a class="a-icon" href.prevent @click="showTreeBox">
              Courses
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu class="customDropDown">
              <treeselect
                v-model="initSelectedCourses"
                :multiple="true"
                :options="treeDataCourses"
              />
            </vs-dropdown-menu>
          </vs-dropdown>
        </vs-col> -->
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="4">
          <label>Last Call</label>
          <date-range-picker
            ref="picker"
            :opens="'left'"
            :locale-data="{
              firstDay: 1,
              format: 'DD-MMM-YYYY',
              customRangeLabel: 'Custom Range',
            }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            style="width: 200px; margin-right: 2%"
            @update="analyzePagination(1)"
          ></date-range-picker>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
            <vs-button radius color="dark" type="gradient" icon="search"></vs-button>
            <vs-dropdown-menu class="loginx">
              <!-- <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Name" v-model="value1" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="search('name')"></vs-button>
                </vs-col>
              </vs-row> -->
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
                  <v-select
                    v-if="spocLogged"
                    :options="SpocCityPrefilled"
                    v-model="SpocPrefilled"
                    :clearable="false"
                    style="margin-top: 10%; margin-right: 1%"
                  />
                  <vs-input
                    type="text"
                    label-placeholder="Can-Id"
                    v-model="value5"
                    :style="{ width: canIdWidth }"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('canId')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Company" v-model="value2" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="search('company')"></vs-button>
                </vs-col> -->
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Mobile" v-model="value3" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('mobile')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Email" v-model="value4" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="3"
                >
                  <vs-button
                    color="dark"
                    type="gradient"
                    icon="search"
                    @click="search('email')"
                  ></vs-button>
                </vs-col>
              </vs-row>
              <!-- <vs-row vs-w="12" style="margin-bottom: 3%">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Comments" v-model="comments" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="search('comments')"></vs-button>
                </vs-col>
              </vs-row>

              <vs-row vs-w="12" style="margin-bottom: 3%" v-if="getSubTeam('INT')">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="Country" v-model="country_search" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="search('comments')"></vs-button>
                </vs-col>
              </vs-row>

              <vs-row vs-w="12" style="margin-bottom: 3%" v-if="getSubTeam('INT')">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                  <vs-input type="text" label-placeholder="City" v-model="city_search" />
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="3">
                  <vs-button color="dark" type="gradient" icon="search" @click="search('comments')"></vs-button>
                </vs-col>
              </vs-row> -->
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-button
            radius
            class="gap"
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refreshdata"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              v-if="chip !== 'divider'"
              closable
              @click="removechip(chip)"
              color="primary"
            >
              <b>{{ chip }}</b>
            </vs-chip>
            <div v-else>
              <div class="vl"></div>
            </div>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="analyzePagination(1)"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col>
          <vs-table max-items="50" :data="googleData">
            <template slot="thead">
              <vs-th>  Can-ID </vs-th>
              <vs-th>Person Name</vs-th>
              <vs-th style="width: 20%">Schedule</vs-th>
              <vs-th>Hangouts Link</vs-th>
              <vs-th style="width: 20%">Message</vs-th>
              <vs-th>Email</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody ref="tbody">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div>{{ tr.identity }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.person_name }}</div>
                  </vs-td>
                  <vs-td>
                    <div>
                      {{ unixTimestampConverter(tr.start_time) }} -
                      {{ unixTimesConverter(tr.stop_time) }}
                    </div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.hangouts_link }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.message }}</div>
                  </vs-td>
                  <vs-td>
                    <div>{{ tr.primary_email }}</div>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ countsleads }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
// import LeadDetailsCard from "../components/pagesComponents/LeadDetailsCard.vue";
import _ from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
// import LeadsNavBar from "../components/pagesComponents/LeadsNavBar.vue";
// import LeadsDataTable from "../components/pagesComponents/LeadsDataTable.vue";
import FocusSearch from "../components/pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../components/pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import EventBus from "../components/eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import moment from "moment";
export default {
  components: {
    Datepicker,
    // LeadsNavBar,
    // LeadsDataTable,
    VTree,
    VSelectTree,
    DateRangePicker,
    FocusSearch,
    SpocSearchTree,
    Treeselect,
    "v-select": vSelect,
    VuePerfectScrollbar,
    moment,
    // LeadDetailsCard,
  },
  data() {
    return {
      dropdownKey: 0,
      googleData: [],
      countsleads: 0,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      settings: {
        maxScrollbarLength: 60,
      },
      spocLogged: false,
      canIdWidth: "100px",
      SpocPrefilled: "",
      spocCityFirst: "",
      nameAutocomplete: [],
      country_search: "",
      city_search: "",
      initSelected: undefined,
      initSelectedBDE: [],
      initSelectedBDESR: [],
      initSelectedBDEIIML: [],
      comments: "",
      initSelectedCourses: [],
      initSelectedLevels: [],
      initSelectedLevelsIIML: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataBDESR: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataBDEIIML: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA"
            },
            {
              id: "CPA-US",
              label: "CPA-US"
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "USP",
              label: "USP",
            },
            // {
            //   id: "RPO",
            //   label: "RPO"
            // },
            {
              id: "IIML-FA",
              label: "IIML-FA",
            },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            {
              id: "IIML-SF",
              label: "IIML-SF",
            },
            {
              id: "IIML-DA",
              label: "IIML-DA",
            },
            {
              id: "IIML-HR",
              label: "IIML-HR",
            },
            {
              id: "IITR-BF",
              label: "IITR-BF",
            },
            {
              id: "IITR-DB",
              label: "IITR-DB",
            },
            {
              id: "IITM-AA",
              label: "IITM-AA",
            },
            {
              id: "IIMK-CX",
              label: "IIMK-CX",
            },
            { id: "IITM-FS", label: "IITM-FS" },
            { id: "IITR-CC", label: "IITR-CC" },
            { id: "IIMK-FT", label: "IIMK-FT" },
            { id: "IIML-AB", label: "IIML-AB" },
            { id: "IIML-SH", label: "IIML-SH" },
            { id: "IITJ-DE", label: "IITJ-DE" },
            { id: "XLRI-HR", label: "XLRI-HR" },
            { id: "XLRI-SH", label: "XLRI-SH" },
            { id: "IIMI-BA", label: "IIMI-BA" },
            { id: "IIMI-AA", label: "IIMI-AA" },
            { id: "XLRI-DM", label: "XLRI-DM" },
            {
              id: "IIML-PM",
              label: "IIML-PM",
            },
            // {
            //   id: "MAYBE",
            //   label: "MAYBE"
            // }
          ],
        },
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                // {
                //   id: "L2",
                //   label: "L2",
                // },
                // {
                //   id: "L3",
                //   label: "L3",
                // },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "M7",
              label: "M7",
            },
            {
              id: "N/A",
              label: "N/A",
            },
            {
              id: "DND",
              label: "DND",
            },
          ],
        },
      ],
      treeDataLevelsIIML: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "Cold", label: "Cold" },
            { id: "Hot", label: "Hot" },
            { id: "Warm", label: "Warm" },
            { id: "Very Hot", label: "Very Hot" },
            { id: "Application Received", label: "Application Received" },
            { id: "Exam-Pass", label: "Exam-Pass" },
            { id: "Exam-Fail", label: "Exam-Fail" },
            { id: "Exam-Pass-Dropout", label: "Exam-Pass-Dropout" },
            { id: "Exam-Fail-Dropout", label: "Exam-Fail-Dropout" },
            { id: "Exam-Noshow-Dropout", label: "Exam-Noshow-Dropout" },
            { id: "Net Enquiry", label: "Net Enquiry" },
            { id: "Busy/NL", label: "Busy/NL" },
            { id: "Application Approved", label: "Application Approved" },
            { id: "Enrolled", label: "Enrolled" },
            { id: "Final payment Received", label: "Final payment Received" },
            { id: "NA", label: "NA" },
            // { id: "Enquiry", label: "Enquiry" },
            // { id: "RPO-M7", label: "RPO-M7" },
            // { id: "IIML-FA-M7", label: "IIML-FA-M7" },
            // { id: "IIML-SF-M7", label: "IIML-SF-M7" },
            // { id: "IIML-FT-M7", label: "IIML-FT-M7" },
            // { id: "IIML-BA-M7", label: "IIML-BA-M7" },
            // { id: "IIML-PM-M7", label: "IIML-PM-M7" },
            // { id: "IIML-HR-M7", label: "IIML-HR-M7" },
            // { id: "IITR-BF-M7", label: "IITR-BF-M7" },
            // { id: "IITR-DB-M7", label: "IITR-DB-M7" },
            // { id: "IITM-AA-M7", label: "IITM-AA-M7" },
            // { id: "IIMK-CX-M7", label: "IIMK-CX-M7" },
            // { id: "IITM-FS-M7", label: "IITM-FS-M7" },
            // { id: "IITR-CC-M7", label: "IITR-CC-M7" },
            // { id: "IIMK-FT-M7", label: "IIMK-FT-M7" },
            // { id: "IIML-AB-M7", label: "IIML-AB-M7" },
            // { id: "IIML-SH-M7", label: "IIML-SH-M7" },
            // { id: "IITJ-DE-M7", label: "IITJ-DE-M7" },
            // { id: "XLRI-HR-M7", label: "XLRI-HR-M7" },
            // { id: "IIMI-BA-M7", label: "IIMI-BA-M7" },
            // { id: "XLRI-DM-M7", label: "XLRI-DM-M7" },
            // { id: "CM-Yet-To-Connect", label: "CM-Yet-To-Connect" },
          ],
        },
      ],
      // treeDataLevelsIIML: [
      //   {
      //     id: "All",
      //     label: "All",
      //     children: [
      //       {
      //         id: "CM",
      //         label: "M7"
      //       },
      //       {
      //         id: "N/A",
      //         label: "N/A"
      //       },
      //       {
      //         id: "DND",
      //         label: "DND"
      //       }
      //     ]
      //   }
      // ],
      treeDatafocus: [
        {
          id: "delays",
          label: "Delays",
        },
        {
          id: "today",
          label: "Today",
        },
        {
          id: "waiting",
          label: "Waiting",
        },
        {
          id: "untapped",
          label: "Untapped",
        },
      ],
      tablelinks: 0,
      currentTablePage: 1,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      searchIn: {
        id: "",
        name: "",
      },
      spoc_city: "",
      rawBde: [],
      rawBdeSR: [],
      rawBdeIIML: [],
      rawcities: [],
      infosearch: [],
      SpocCityPrefilled: [],
      searchCriteria: [],
      bulkdata: [],
      count: 0,
      mwb_names: [],
    };
  },
  mounted() {
    // if (localStorage.getItem("role") === "") {
    //   localStorage.setItem("role", "cm1");
    // }
    // A new coomment
    // this.cm2Redirect();
    this.$store.commit("CLEAR_DASHBOARD_LEADS");
    if (
      localStorage.getItem("role") === "cm4" ||
      localStorage.getItem("role") === "wc" ||
      localStorage.getItem("role") === "ac2" ||
      localStorage.getItem("role") === "fe" ||
      localStorage.getItem("role") === "cmca" ||
      localStorage.getItem("role") === "baca" ||
      localStorage.getItem("role") === "hr"
    ) {
      this.spocLogged = false;
      this.canIdWidth = "100%";
      this.getSpocAdmin();
      this.getSpocAdminIIML();
    } else {
      this.spocLogged = true;
      this.canIdWidth = "100px";
      this.getSpoc();
      this.getExed();
    }
    EventBus.$on("open-email-leadsdatatable", (payload) => {
      this.bulkdata = [];
      for (let y = 1; y <= 5; y++) {
        this.search_API(y, true, payload);
      }
      this.bulkdata.forEach((bulk) => {
        console.log(bulk);
      });
    });
    EventBus.$on("reload-leads", () => {
      this.analyzePagination(this.currentTablePage);
    });
    EventBus.$on("reloadEntireData", () => {
      this.getSpoc();
      this.getExed();
    });
  },
  watch: {
    initSelectedBDEIIML: function () {
      this.mergeAllSearch();
    },
    initSelectedBDESR: function () {
      this.mergeAllSearch();
    },
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    initSelected: function () {
      this.mergeAllSearch();
    },
    initSelectedCourses: function () {
      this.mergeAllSearch();
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    initSelectedLevelsIIML: function () {
      this.mergeAllSearch();
    },
    currentTablePage: async function (val) {
      this.analyzePagination(val);
    },
    infosearch: function () {
      this.mergeAllSearch();
    },
  },
  methods: {
    unixTimestampConverter(value) {
      var dateString = moment.unix(value).format("DD/MM/YYYY h:m:s a");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    unixTimesConverter(value) {
      var dateString = moment.unix(value).format("h:m:s a");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
    scrollHandle(evt) {
      console.log(evt);
    },
    autoCompleteName(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      this.fetchMwbs(loading, search, this);
    },
    fetchMwbs: _.debounce((loading, search, vm) => {
      let url = `${constants.SERVER_API}searchMwbName?name=${escape(search)}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          vm.mwb_names = response.data.map((lead) => lead.person_name);
          loading(false);
        });
    }, 350),
    openEmail() {
      this.EmitEvent("open-email-bdeleads");
    },
    removechip(chip) {
      // console.log(chip);
      // console.log(this.treeDataBDE);
      // console.log(this.treeDataLevels);
      // console.log(this.treeDataCourses);
      // console.log(this.treeDatafocus);
      if (this.infosearch.indexOf(chip) !== -1) {
        this.infosearch.splice(this.infosearch.indexOf(chip), 1);
      }
      let chipPlacement = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "exed" ||
        localStorage.getItem("role") === "exed2"
      ) {
        this.treeDataBDE[0].children.forEach((child) => {
          child.children.forEach((child2) => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach((child) => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      this.treeDataCourses[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevelsIIML[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "iimllevels";
        }
      });
      this.treeDataLevels[0].children.forEach((child) => {
        if (chip === "M7" || chip === "N/A" || chip === "DND") {
          chipPlacement = "levels";
        } else {
          if ("children" in child) {
            child.children.forEach((chi) => {
              if (chi.label === chip) {
                chipPlacement = "levels";
              }
            });
          }
        }
      });
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      }
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (
              localStorage.getItem("role") === "cm4" ||
              localStorage.getItem("role") === "wc" ||
              localStorage.getItem("role") === "ac2" ||
              localStorage.getItem("role") === "fe" ||
              localStorage.getItem("role") === "cmca" ||
              localStorage.getItem("role") === "baca" ||
              localStorage.getItem("role") === "hr"
            ) {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(city), 1);
                this.treeDataBDE[0].children.forEach((child) => {
                  if (child.label === city) {
                    child.children.forEach((child2) => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach((child) => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
          }
          break;
        case "iimllevels":
          if (this.initSelectedLevelsIIML.indexOf(chip) === -1) {
            this.initSelectedLevelsIIML = [];
            this.treeDataLevelsIIML[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedLevelsIIML.push(child.label);
              }
            });
          } else {
            this.initSelectedLevelsIIML.splice(
              this.initSelectedLevelsIIML.indexOf(chip),
              1
            );
          }
          break;
        case "levels":
          if (this.initSelectedLevels.indexOf("All") !== -1) {
            this.initSelectedLevels = [];
            this.treeDataLevels[0].children.forEach((child) => {
              child.children.forEach((chi) => {
                if (chi.label !== chip) {
                  this.initSelectedLevels.push(chi.label);
                }
              });
            });
          } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
            this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
          } else {
            let childProperty = "";
            this.treeDataLevels[0].children.forEach((child) => {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label === chip) {
                    childProperty = child.label;
                  }
                });
              }
            });
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(childProperty),
              1
            );
            this.treeDataLevels[0].children.forEach((child) => {
              if (child.label === childProperty) {
                if ("children" in child) {
                  child.children.forEach((chi) => {
                    if (chi.label !== chip) {
                      this.initSelectedLevels.push(chi.label);
                    }
                  });
                }
              }
            });
          }
      }
    },
    analyzePagination(val) {
      if (this.currentTablePage === 1) {
        this.search_API(1);
      } else {
        this.search_API(val);
      }
    },
    async search_API(page, copyfor) {
      //   let focus = this.initSelected;
      //   if (this.initSelected !== null && this.initSelected !== undefined) {
      //     focus = this.initSelected.toLowerCase();
      //   }
      let bde = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });

        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
        this.initSelectedBDEIIML.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDEIIML[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDEIIML[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }

      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });

        this.rawBdeSR.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
        this.rawBdeIIML.forEach((raw) => {
          if (raw.first_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      //   let visitors = "";
      //   let net_enquiry = "";
      //   let eligibility = "";
      //   let reference = "";
      //   let loan = "";
      //   let corporate = "";
      //   let university = "";
      //   this.infosearch.forEach((value) => {
      //     switch (value) {
      //       case "Visitors":
      //         visitors = "Yes";
      //         break;
      //       case "Net Enquiry":
      //         net_enquiry = "Yes";
      //         break;
      //       case "Eligibility":
      //         eligibility = "Yes";
      //         break;
      //       case "Reference":
      //         reference = "Yes";
      //         break;
      //       case "Loan":
      //         loan = "Yes";
      //         break;
      //       case "Corporate":
      //         corporate = "Yes";
      //         break;
      //       case "University":
      //         university = "Yes";
      //         break;
      //     }
      //   });
      let obj = {
        // courses: courses.join(),
        // levels: levels.join(),
        // my_focus: focus,
        spoc_ids: bdeid.join(),
        // iiml_levels: iimllevels.join(),
        // name: this.value1,
        identity: this.value5,
        // company: this.value2,
        mobile: this.value3,
        email: this.value4,
        // visitors: visitors,
        // net_enquiry: net_enquiry,
        // eligibility: eligibility,
        // reference: reference,
        // loan: loan,
        // comments: this.comments,
        // international_city: this.city_search,
        // country: this.country_search,
        // corporate: corporate,
        // university: university,
        from_date: "",
        to_date: "",
      };
      if (
        localStorage.getItem("role") !== "fe" &&
        localStorage.getItem("role") !== "cmca" &&
        localStorage.getItem("role") !== "baca" &&
        localStorage.getItem("role") !== "hr" &&
        localStorage.getItem("role") !== "cm4" &&
        localStorage.getItem("role") !== "ac2" &&
        localStorage.getItem("role") !== "wc" &&
        this.value5 !== ""
      ) {
        obj.identity = `${this.SpocPrefilled}-${this.value5}`;
      }
      if (this.dateRange.startDate !== "" && this.dateRange.endDate !== "") {
        obj.from_date = this.datatoTimestamp(this.dateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.dateRange.endDate);
      }
      // console.log(obj);
      if (copyfor === true) {
        console.log("Is this even running? Yes it is.");
        axios
          .get(`${constants.SERVER_API}getMeetEvents?page=${page}`, {
            params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            response.data.data.forEach((lead) => {
              if (this.bulkdata.length !== 50) {
                this.bulkdata.push(lead);
              }
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
        axios
          .get(
            `${constants.SERVER_API}getMeetEvents?page=${page}`,

            {
              params: obj,
              headers: {
                Authorization: `Bearer ${localStorage.userAccessToken}`,
              },
            }
          )
          .then((response) => {
            console.log("laedas data", response);
            this.googleData = response.data.data;
            this.countsleads = response.data.total;
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.emails = [];
            });
            this.currentTablePage = response.data.current_page;
            this.tablelinks = response.data.last_page;
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    refreshdata() {
      EventBus.$emit("refresh-focus");
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.comments = "";
      this.value5 = "";
      this.country_search = "";
      this.city_search = "";
      this.infosearch = [];
      this.initSelected = "";
      this.initSelectedBDE = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.getSpocAdmin();
        this.getSpocAdminIIML();
      } else {
        this.getSpoc();
        this.getExed();
      }
    },
    getSpoc() {
      this.initSelectedBDE = [];

      let url = ``;

      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "ac1"
      ) {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (
        localStorage.getItem("role") === "exed" ||
        localStorage.getItem("role") === "exed2"
      ) {
        url = `${constants.SERVER_API}getExed`;
      }
      // else if (localStorage.getItem("role") === "exed2") {
      //   url = `${constants.SERVER_API}getExed2`;
      // }

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          // console.log("spocs", response.data.spocs);
          this.rawBde = [];
          let usercity = "";
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          // console.log(unique, "Unique");
          this.SpocCityPrefilled = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.SpocCityPrefilled.push(key.charAt(0).toUpperCase());
            }
          }
          response.data.spocs.forEach((spoc) => {
            if (spoc.id === this.searchIn.id) {
              usercity = spoc.city;
              this.spoc_city = spoc.city;
              this.SpocPrefilled = spoc.city.charAt(0).toUpperCase();
            }
          });
          response.data.spocs.forEach((spoc) => {
            if (this.getrole("cm1")) {
              if (spoc.city === usercity) {
                this.rawBde.push(spoc);
              }
            } else if (this.getrole("exed") || this.getrole("exed2")) {
              this.rawBde.push(spoc);
            }
          });
          this.treeDataBDE[0].children = [];
          this.rawBde.forEach((data) => {
            var obj = {
              id: data.first_name,
              label: data.first_name,
            };
            this.treeDataBDE[0].children.push(obj);
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "exed" ||
            localStorage.getItem("role") === "exed2"
          ) {
            // console.log(this.treeDataBDE[0].children);
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBde = response.data.spocs;
          this.treeDataBDE[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDE[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDE.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (this.rawBde.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          //   this.getExed();
          this.getSRSpoc();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpoc() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBdeSR = response.data.spocs;
          this.treeDataBDESR[0].children = [];
          this.rawcities = [];
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              this.treeDataBDESR[0].children.push(obj);
            }
          }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDESR.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.city) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name,
                  };
                  bdechid.children.push(obj);
                }
              });
            });
          });
          this.treeDataBDESR[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDESR[0].children
          );
          if (this.rawBdeSR.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getExed() {
      let url = `${constants.ADMIN_SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.rawBdeIIML = response.data.spocs;
          this.rawBdeSR.forEach((sr) => {
            this.rawBde.push(sr);
          });
          this.treeDataBDEIIML[0].children = [];
          response.data.spocs.forEach((data) => {
            var obj = {
              id: data.first_name,
              label: data.first_name,
            };
            this.treeDataBDEIIML[0].children.push(obj);
          });
          this.initSelectedBDEIIML.push(this.searchIn.name);
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdminIIML() {
      let url = `${constants.ADMIN_SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
          this.rawBdeIIML = response.data.spocs;
          // console.log('response.data.spocs',response.data.spocs)
          this.rawBdeSR.forEach((sr) => {
            this.rawBde.push(sr);
          });
          this.treeDataBDEIIML[0].children = [];
          this.rawcities = [];
          // response.data.spocs = response.data.spocs.forEach(element => {
          //   console.log('response data',element.sub_team)
          // });
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.sub_team,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});

          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.rawcities.push(key);

              // console.log('this rawcities',this.treeDataBDE)
              var obj = {
                id: key,
                label: key,
                children: [],
              };
              if (
                obj.label !== "null" &&
                obj.label !== "undefined" &&
                obj.label !== "IIML-FA" &&
                obj.label !== "IIML-SF" && 
                obj.label !== "IIML-FT"
              ) {
                this.treeDataBDEIIML[0].children.push(obj);
              }
            }
          }
          // const index = this.rawcities.indexOf(null);
          // if (index > -1) {
          //   this.rawcities.splice(index, 1);
          // }
          response.data.spocs.forEach((server_spoc) => {
            this.treeDataBDEIIML.forEach((bde) => {
              bde.children.forEach((bdechid) => {
                if (bdechid.label === server_spoc.sub_team) {
                  var obj = {
                    id: server_spoc.first_name,
                    label: server_spoc.first_name,
                  };
                  bdechid.children.push(obj);
                }
              });

              // console.log('bde.children',bde.children)
            });
          });
          this.treeDataBDEIIML[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDEIIML[0].children
          );
          if (this.rawBdeIIML.indexOf(this.searchIn) !== -1) {
            this.initSelectedBDEIIML.push(this.searchIn.name);
          }
          // else {
          // this.initSelectedBDE.push(this.treeDataBDE[0].children[0].label);
          // }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });

        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
        this.initSelectedBDEIIML.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDEIIML[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDEIIML[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "" && this.initSelected !== undefined) {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach((info) => {
        this.searchCriteria.push(info);
      });

      this.searchCriteria.push("divider");
      this.initSelectedLevelsIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevelsIIML[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
    },
    clear() {
      this.value1 = "";
      this.value2 = "";
    },
    search() {
      this.dropdownKey = Math.random()
      this.search_API();
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise((resolve) => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" },
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    consolelog() {
      // console.log("clicked");
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
</style>
